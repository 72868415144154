<script setup lang="ts">
  const articleEl = ref();

  const navigationStore = useNavigationStore();

  useIntersectionObserver(
    articleEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'article');
      if (tab) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div
    id="article"
    ref="articleEl"
    data-nav="Информация"
    class="info-container scroll-normalize main-container"
  >
    <div class="info-text-container">
      <slot></slot>
    </div>
    <slot name="author"></slot>
  </div>
</template>

<style scoped lang="scss">
.info-container {
  display: flex;
  gap: 60px;
  padding: 40px 0px;
  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 30px 20px;
  }
}
.info-text-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: 673px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
}
.scroll-normalize {
  scroll-margin-top: 160px;
  @media (max-width: 1023px) {
    scroll-margin-top: 120px;
  }
}
</style>
